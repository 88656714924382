import { useState, useEffect, createRef } from 'react';

import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

import { Box, Button, Card, CardContent, CircularProgress, Grid, IconButton, InputAdornment, MenuItem, Skeleton, TextField, Typography } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import * as Yup from 'yup';
import TABLE_ICONS from 'tableIcons';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import NewFileTypeModal from 'ui-component/modals/NewFileTypeModal';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import Status from 'ui-component/buttons/Status';
import MAddDriverSafetyComplianceForm from './MAddDriverSafetyComplianceForm';

import { Stack, useMediaQuery } from '@mui/system';
import DocModal from 'ui-component/modals/DocModal';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import MAddTruckSafetyComplianceForm from './MAddTruckSafetyComplianceForm';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const ListTruckSafetyComplianceForm = ({ id, docId, cid, tId, docName }) => {
    const dispatch = useDispatch();
    const tableRef = createRef();

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

    const [docList, setDocList] = useState();
    const [fileId, setFileId] = useState();

    const [loading, setLoading] = useState();

    const [addError, setAddError] = useState();
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [fileName, setFileName] = useState('');

    // const scriptedRef = useScriptRef();
    // const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [docData, setDocData] = useState();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const driverConfig = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
        setFileId(null);
    };
    const handleClickOpenModal = (id) => {
        setFileId(id);
        setOpen(true);

        // e === 'add' ? setOpen(true) : '';
    };

    const reloadData = () => {
        setOpen(false);
        setFileId(null);
        safteyDocList();
    };

    const handleClickOpen = (file) => {
        setFileName(file);
        setModalOpen(true);
    };

    const handleClose = () => {
        // setFileName();
        setModalOpen(false);
    };

    function handleDownloadModal() {
        setModalOpen(false);
        window.open(fileName, '_blank');
        setFileName('');
    }

    const handleDeleteModalDoc = async (id) => {
        try {
            const { data } = await axios.delete(`/v1/api/fleet/safety/doc-view/${id}/`, config);
            safteyDocList();
        } catch (error) {
            console.log(error);
        }
    };

    async function handleDownloadModalDoc(file) {
        const body = {
            action: 'GET',
            url: file,
            changed_object: 'Downloaded Driver Qualification',
            event_category: 'Get',
            change_summary: `Downloaded Driver Qualification`
        };
        window.open(file, '_blank');
        try {
            await axios.post(`../../v1/api/users/activity-logs/`, body, config);
        } catch (error) {
            console.log(error);
        }
    }

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor tablePadding">File Name</th>
                    <th className="headerTd rightHidBor tablePadding">DQ Status</th>

                    <th className="headerTd rightHidBor tablePadding">Date </th>
                    <th className="headerTd rightHidBor tablePadding">Expire Date</th>
                    <th className="headerTd rightHidBor tablePadding">File Status</th>
                    <th className="headerTd rightHidBor tablePadding">Descripction</th>
                    <th className="headerTd">File Type</th>
                    <th className="headerTd">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor  tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.sft_doc_name}
                </td>
                <td className="rowTr rightHidBor  tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.doc_status === 'Submitted' ? (
                        <Status text="Submitted" />
                    ) : rowData.data.doc_status === 'Not Submitted' ? (
                        <Status text="Not Submitted" />
                    ) : (
                        <Status text="Incomplete" />
                    )}
                </td>
                <td className="rowTr rightHidBor  tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.issue_date && format(new Date(rowData.data.issue_date), 'yyyy-MM-dd')}
                </td>
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.expiry_date && format(new Date(rowData.data.expiry_date), 'yyyy-MM-dd')}
                </td>

                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="Inactive" />}
                </td>
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.description}
                </td>
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.sft_doc_name && rowData.data.sft_doc_name.split('.').pop()}
                </td>
                {/* Custom cell or additional cells */}
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => handleClickOpenModal(rowData.data.drv_doc_id)}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => handleClickOpen(rowData.data.doc_url)} disabled={rowData.data.doc_status !== 'Submitted'}>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => handleDownloadModalDoc(rowData.data.sft_doc)} disabled={rowData.data.doc_status !== 'Submitted'}>
                        <FileDownloadIcon fontSize="small" style={{ margin: '-3px' }} />
                    </IconButton>{' '}
                    <IconButton onClick={() => handleDeleteModalDoc(rowData.data.drv_doc_id)}>
                        <DeleteIcon fontSize="small" color="error" style={{ margin: '-3px' }} />
                    </IconButton>
                </td>
            </tr>
        );
    };

    const safteyDocList = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(
                `/v1/api/fleet/safety/doc/list?page=1&page_size=100&company_id=${cid}&driver_id=${id}&truck_id=${tId}&doc_type_id=${docId}`,
                config
            );

            setDocList(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!docList) {
            safteyDocList();
        }
        // if (docId && !docData) {
        //     getDocData();
        // }
    }, [docList]);

    console.log(fileName);

    return (
        <>
            {open && (
                <MAddTruckSafetyComplianceForm
                    open={open}
                    handleClose={handleCloseModal}
                    id={id}
                    docId={fileId}
                    typeId={docId}
                    tId={tId}
                    reloadData={reloadData}
                />
            )}
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Typography variant="h4" color="primary">
                    {docName}
                </Typography>
                <Button
                    variant="contained"
                    sx={{ mr: { xs: 0, sm: 2 }, color: 'white', backgroundColor: '#EB812E' }}
                    color="orange"
                    onClick={() => setOpen(true)}
                >
                    ADD {docName}
                </Button>
            </Stack>
            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        <>
                            <div style={{ marginTop: '15px' }}>
                                {docList &&
                                    docList.map((driver) => (
                                        <Card
                                            key={driver.driver_id}
                                            sx={{ marginBottom: '1.5rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '15px' }}
                                        >
                                            <CardContent>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                                    {driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}
                                                    <IconButton onClick={() => handleClickOpenModal(driver.drv_doc_id)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleClickOpen(driver.doc_url)} disabled={driver.doc_status !== 'Submitted'}>
                                                        <VisibilityIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => handleDownloadModalDoc(driver.sft_doc)}
                                                        disabled={driver.doc_status !== 'Submitted'}
                                                    >
                                                        <FileDownloadIcon fontSize="small" style={{ margin: '-3px' }} />
                                                    </IconButton>{' '}
                                                    <IconButton onClick={() => handleDeleteModalDoc(driver.drv_doc_id)}>
                                                        <DeleteIcon fontSize="small" color="error" style={{ margin: '-3px' }} />
                                                    </IconButton>
                                                    {/* <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div style={{ float: 'right', marginTop: '1px' }}>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        <MenuItem onClick={() => handleClickOpenModal(driver.drv_doc_id)}>Edit</MenuItem>
                                                        <MenuItem onClick={() => handleDownloadModalDoc(driver.sft_doc)}>Download</MenuItem>
                                                        {!driver.is_mand && <MenuItem onClick={() => handleDeleteModalDoc(driver.drv_doc_id)}>Delete</MenuItem>}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState> */}
                                                </Box>

                                                <Box sx={{ clear: 'both' }} />
                                                <Typography sx={{ marginBottom: '.5rem' }}>File Name: {driver.sft_doc_name}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>
                                                    VQ Status{' '}
                                                    {driver.doc_status === 'Submitted' ? (
                                                        <Status text="Submitted" />
                                                    ) : driver.doc_status === 'Not Submitted' ? (
                                                        <Status text="Not Submitted" />
                                                    ) : (
                                                        <Status text="Incomplete" />
                                                    )}
                                                </Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>
                                                    Date: {driver.issue_date && format(new Date(driver.issue_date), 'yyyy-MM-dd')}
                                                </Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>
                                                    Expire Date: {driver.expiry_date && format(new Date(driver.expiry_date), 'yyyy-MM-dd')}
                                                </Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Description: {driver.description}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>File : {driver.sft_doc_name.split('.').pop()}</Typography>
                                            </CardContent>
                                        </Card>
                                    ))}
                            </div>
                        </>
                    )}
                    {/* <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    /> */}
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        docList &&
                        docList?.map((driver) => (
                            <Card
                                key={driver.driver_id}
                                sx={{ marginTop: '2rem', marginBottom: '1.5rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '15px' }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>
                                        <IconButton onClick={() => handleClickOpenModal(driver.drv_doc_id)}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton onClick={() => handleClickOpen(driver.doc_url)} disabled={driver.doc_status !== 'Submitted'}>
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton onClick={() => handleDownloadModalDoc(driver.sft_doc)} disabled={driver.doc_status !== 'Submitted'}>
                                            <FileDownloadIcon fontSize="small" style={{ margin: '-3px' }} />
                                        </IconButton>{' '}
                                        <IconButton onClick={() => handleDeleteModalDoc(driver.drv_doc_id)}>
                                            <DeleteIcon fontSize="small" color="error" style={{ margin: '-3px' }} />
                                        </IconButton>
                                        {/* <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div style={{ float: 'right', marginTop: '1px' }}>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        <MenuItem onClick={() => handleClickOpenModal(driver.drv_doc_id)}>Edit</MenuItem>
                                                        <MenuItem onClick={() => handleDownloadModalDoc(driver.sft_doc)}>Download</MenuItem>
                                                        {!driver.is_mand && <MenuItem onClick={() => handleDeleteModalDoc(driver.drv_doc_id)}>Delete</MenuItem>}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState> */}
                                    </Box>
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '1rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Type of DQ File: {driver.stf_doc_type_name}</Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            DQ Status:{' '}
                                            {driver.doc_status === 'Submitted'
                                                ? 'Submitted'
                                                : driver.doc_status === 'Not Submitted'
                                                ? 'Not Submitted'
                                                : 'Incomplete'}
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '1rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            Date: {driver.issue_date && format(new Date(driver.issue_date), 'yyyy-MM-dd')}
                                        </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            Expire Date: {driver.expiry_date && format(new Date(driver.expiry_date), 'yyyy-MM-dd')}
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '1rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Description: {driver.description}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            File Type: {driver.sft_doc_name && driver.sft_doc_name.split('.').pop()}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    {/* <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                    data={docList ? docList : []}
                    title="All Truck"
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,
                        toolbar: false,
                        draggable: false,
                        columnResizable: true
                    }}
                />
            )}

            <DocModal
                open={modalOpen}
                fullWidth
                fullScreen
                maxWidth="md"
                handleClose={() => handleClose()}
                handleDownload={() => handleDownloadModal()}
                fileName={fileName}
                content={
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer fileUrl={fileName} />
                    </Worker>
                }
            />
        </>
    );
};

ListTruckSafetyComplianceForm.propTypes = {
    reloadData: PropTypes.any,
    id: PropTypes.any,
    docId: PropTypes.any
};

export default ListTruckSafetyComplianceForm;
